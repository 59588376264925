<!--  -->
<template>
  <div class="approval-li">
    <div class="row1">
      <div class="title">{{ info.osName }}</div>
    </div>

    <div class="msg">
      <span>型号：{{ info.modelType }}</span>
    </div>
    <div class="msg">
      <span>数量：{{ info.numCount }}</span>
    </div>
    <div class="msg">
      <span>类型：{{ info.osTypeName }}</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  name: "suppliesItem",
  components: {},
  props: {
    info: Object,
    type: String,
  },
  computed: {},
  filters: {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.approval-li {
  width: 7.1rem;
  background: #ffffff;
  border-radius: 0.2rem;
  margin-bottom: 0.2rem;
  margin-left: 0.2rem;
  padding: 0.2rem;
  .row1 {
    display: flex;
    justify-content: space-between;
    .title {
      font-size: 0.3rem;
      font-weight: 600;
      color: #333333;
      line-height: 0.42rem;
    }
  }
  .msg {
    word-break: break-all;
    font-size: 0.26rem;
    color: #999999;
    line-height: 0.37rem;
    margin-top: 0.12rem;
  }
}
</style>
